import React, { useState } from 'react';
import TopBar from '../components/TopBar';
import Carousel from '../components/Carousel';
import Footer from '../components/Footer';
import { FaBuilding } from "react-icons/fa";
import ExclusiveCarousel from '../components/ExclusiveCarousel';
import TestimonialCarousel from '../components/TestimonialCarousel';
import Building from '../assets/building.webp';
import Mall from '../assets/mall.webp';
import { FaX } from "react-icons/fa6";

const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <TopBar />

      <div className="flex font-semibold text-2xl text-gray-900 bg-gray-100 tracking-wide mt-20 md:mt-32 w-full px-40 py-2">
        <FaBuilding className="text-blue-600 mr-3 " size={35} />
        New Projects
      </div>

      <div className="pt-5 px-5 md:px-40 bg-gray-100" >
        <Carousel />
      </div>

      <div className="text-xs font-bold uppercase text-gray-500 tracking-wide w-full text-center mt-10">
        ALL PROPERTY NEEDS - ONE PORTAL
      </div>

      <div className="w-full text-center font-bold text-3xl md:text-4xl mt-2 mb-2">
        Find Better Places to Live, Work <br className="hidden md:block" /> and Wonder...
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center mt-10 md:mt-14 px-5 md:px-0">
        <div className="w-full md:w-[600px] h-[300px] md:h-[450px] md:mr-20 rounded-lg overflow-hidden mb-10 md:mb-0">
          <img className="h-full w-full cursor-pointer hover:scale-105 transition-all duration-500" src="https://picsum.photos/500/600" alt="" />
        </div>
        <div>
          <p className="text-xs uppercase text-gray-500 tracking-wide font-bold mb-4">Buy a home</p>
          <p className="font-bold text-2xl md:text-3xl mb-3">Find, Buy & Own Your <br />Dream Home</p>
          <p className="text-sm md:text-[17px] mb-4">Explore from Apartments, land, builder floors, <br /> villas and more</p>
          <button className="bg-blue-500 text-white font-bold py-3 px-6 rounded-md cursor-pointer">Explore Buying</button>
        </div>
      </div>

      <section className="flex w-full px-5 md:px-32 mt-20 bg-gray-100">
        <div className="flex flex-col rounded-lg w-full  p-5">
          <div className="flex items-center mb-10 pl-5 md:pl-10">
            <FaBuilding className="text-blue-600 mr-3" size={35} />
            <div>
              <p className="font-semibold text-xl md:text-[25px]">Exclusive Properties</p>
              <p className="text-sm font-semibold text-gray-600">Limited Offer</p>
            </div>
          </div>
          <ExclusiveCarousel />
        </div>
      </section>

      <div className="text-xs font-bold uppercase text-gray-500 tracking-wide w-full text-center mt-10">
        COMMERCIAL SPACES
      </div>

      <div className="w-full text-center font-bold text-3xl md:text-4xl mt-2 mb-2">
        Choose from a wide variety of <br className="hidden md:block" />commercial properties
      </div>

      <div className="flex flex-col md:flex-row h-[450px] mt-10 mb-20 justify-center w-full px-5 md:px-0">
        <div className="w-full md:w-[480px] rounded-lg mr-0 md:mr-5 overflow-hidden h-full relative flex flex-col justify-center cursor-pointer mb-10 md:mb-0" >
          <div className="absolute w-full h-full top-0 left-0 z-[0]">
            <img className="h-full w-full cursor-pointer hover:scale-105 transition-all duration-500" src={Building} alt="" />
          </div>
          <p className="pl-5 md:pl-12 z-[10] text-gray-400 font-bold uppercase tracking-wide mb-4">Buy for commercial use</p>
          <p className="pl-5 md:pl-12 z-[10] text-2xl md:text-3xl font-bold mb-4">Buy a Commercial <br />property</p>
          <p className="pl-5 md:pl-12 z-[10] text-sm md:text-[17px] text-gray-600 mb-4">Explore from Office Spaces, Co-working spaces, <br />Retail Shops, Land, Factories and more</p>
          <button className="w-[200px] md:w-[250px] ml-5 md:ml-12 font-semibold z-[10] py-3 bg-blue-600 text-white rounded-md">Explore Buying Commercial</button>
        </div>

        <div className="w-full md:w-[480px] rounded-lg overflow-hidden h-full relative flex flex-col justify-center cursor-pointer">
          <div className="absolute w-full h-full top-0 left-0 z-[0]">
            <img className="h-full w-full cursor-pointer hover:scale-105 transition-all duration-500" src={Mall} alt="" />
          </div>
          <p className="pl-5 md:pl-12 z-[10] text-gray-400 font-bold uppercase tracking-wide mb-4">Lease for commercial use</p>
          <p className="pl-5 md:pl-12 z-[10] text-2xl md:text-3xl font-bold mb-4">Lease a Commercial <br />property</p>
          <p className="pl-5 md:pl-12 z-[10] text-sm md:text-[17px] text-gray-600 mb-4">Explore from Office Spaces, Co-working spaces, <br />Retail Shops, Land, Factories and more</p>
          <button className="w-[200px] md:w-[250px] ml-5 md:ml-12 font-semibold z-[10] py-3 bg-blue-600 text-white rounded-md">Explore Leasing Commercial</button>
        </div>
      </div>

      <div className="text-xs bg-gray-100 font-bold uppercase text-gray-500 tracking-wide w-full text-center pt-10">
        BENEFITS OF RENTAL WEB
      </div>

      <div className="bg-gray-100 w-full text-center font-bold text-3xl md:text-4xl pt-2 pb-2">
        Why choose Rental Web?
      </div>

      <section className="bg-gray-100 w-full flex flex-col md:flex-row justify-center items-center pt-10 mb-10 pb-5">
        <div className="flex flex-col items-center md:items-start text-center md:text-left mb-10 md:mb-0 md:mr-20">
          <div className="w-10 h-10 rounded-full overflow-hidden mb-6">
            <img src="https://picsum.photos/200/200" alt="" />
          </div>
          <div className="text-[20px] flex items-center font-semibold mb-2">
            <p className="text-blue-500 text-[17px] mr-1">01.</p>Over 12 Lac properties
          </div>
          <p className="text-gray-400 text-sm md:text-[17px]">10,000+ properties are added every day</p>
        </div>
        <div className="flex flex-col items-center md:items-start text-center md:text-left mb-10 md:mb-0 md:mr-20">
          <div className="w-10 h-10 rounded-full overflow-hidden mb-6">
            <img src="https://picsum.photos/200/200" alt="" />
          </div>
          <div className="text-[20px] flex items-center font-semibold mb-2">
            <p className="text-blue-500 text-[17px] mr-1">02.</p>Verification by Rental Web
          </div>
          <p className="text-gray-400 text-sm md:text-[17px]">Photos/Videos and other details are <br /> verified on location</p>
        </div>
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <div className="w-10 h-10 rounded-full overflow-hidden mb-6">
            <img src="https://picsum.photos/200/200" alt="" />
          </div>
          <div className="text-[20px] flex items-center font-semibold mb-2">
            <p className="text-blue-500 text-[17px] mr-1">03.</p>Large user base
          </div>
          <p className="text-gray-400 text-sm md:text-[17px]">High active user count and user <br /> engagement to find and close deals</p>
        </div>
      </section>

      <div className="w-full px-5 md:px-28 mt-10 mb-20">
        <TestimonialCarousel />
      </div>



      <Footer />
    </>
  );
};

export default HomePage;
