import React, { useState } from 'react'
import TopBar2 from '../components/TopBar2'
import SearchCard from '../components/SearchCard'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa6'

const SearchPage = () => {

  const data = [
    {
      name: "New Property",
      propertySpecs: "2 BHK",
      prpopertyType: "residential",
      location: "Shanti Park, Mira Road East",
      price: "95 Lac",
      pricePerSqft: "9519",
      area: "998",
      areaType: "Carpet Area",
      propertyStatus: "Ready to Move",
      description: "description asd asd as dasdasdqwdqfgwetgwf asfasfas d",
      phone: "9876543212",
      owner: "AZ Properties",
    },
    {
      name: "New Property",
      propertySpecs: "2 BHK",
      prpopertyType: "residential",
      location: "Shanti Park, Mira Road East",
      price: "95 Lac",
      pricePerSqft: "9519",
      area: "998",
      areaType: "Carpet Area",
      propertyStatus: "Ready to Move",
      description: "description",
      phone: "9876543212",
      owner: "AZ Properties",
    },
    {
      name: "New Property",
      propertySpecs: "2 BHK",
      prpopertyType: "residential",
      location: "Shanti Park, Mira Road East",
      price: "95 Lac",
      pricePerSqft: "9519",
      area: "998",
      areaType: "Carpet Area",
      propertyStatus: "Ready to Move",
      description: "description",
      phone: "9876543212",
      owner: "AZ Properties",
    },
    {
      name: "New Property",
      propertySpecs: "2 BHK",
      prpopertyType: "residential",
      location: "Shanti Park, Mira Road East",
      price: "95 Lac",
      pricePerSqft: "9519",
      area: "998",
      areaType: "Carpet Area",
      propertyStatus: "Ready to Move",
      description: "description",
      phone: "9876543212",
      owner: "AZ Properties",
    },
    {
      name: "New Property",
      propertySpecs: "2 BHK",
      prpopertyType: "residential",
      location: "Shanti Park, Mira Road East",
      price: "95 Lac",
      pricePerSqft: "9519",
      area: "998",
      areaType: "Carpet Area",
      propertyStatus: "Ready to Move",
      description: "description",
      phone: "9876543212",
      owner: "AZ Properties",
    },
    {
      name: "New Property",
      propertySpecs: "2 BHK",
      prpopertyType: "residential",
      location: "Shanti Park, Mira Road East",
      price: "95 Lac",
      pricePerSqft: "9519",
      area: "998",
      areaType: "Carpet Area",
      propertyStatus: "Ready to Move",
      description: "description",
      phone: "9876543212",
      owner: "AZ Properties",
    },
    {
      name: "New Property",
      propertySpecs: "2 BHK",
      prpopertyType: "residential",
      location: "Shanti Park, Mira Road East",
      price: "95 Lac",
      pricePerSqft: "9519",
      area: "998",
      areaType: "Carpet Area",
      propertyStatus: "Ready to Move",
      description: "description",
      phone: "9876543212",
      owner: "AZ Properties",
    }
  ]

  const [propertyOpen, setPropertyOpen] = useState(true);
  const [bedroomsOpen, setBedroomsOpen] = useState(true);
  const [constructionOpen, setConstructionOpen] = useState(false);
  const [amenitiesOpen, setAmenitiesOpen] = useState(false);
  const [sellingTypeOpen, setSellingTypeOpen] = useState(false);
  const [minArea, setMinArea] = useState(0);
  const [maxArea, setMaxArea] = useState(20000);



  return (
    <>
      <TopBar2 />
      <section className="flex w-full justify-center pt-10 bg-gray-100">
       
       
        <div className="w-[350px] border min-h-[450px] max-h-[1400px]  rounded-lg shadow flex flex-col p-4 mr-5">
          <p className="font-semibold text-lg mb-5">Filter</p>

          {/* Type of Property Section */}
          <div>
            <p className="cursor-pointer font-semibold text-lg mb-3 flex w-full justify-between" onClick={() => setPropertyOpen(!propertyOpen)}>
              <p>Type of Property</p>
              {propertyOpen ? <p><FaChevronUp size={15} className="text-gray-600 " /></p> : <p><FaChevronDown size={15} className="text-gray-600 " /></p>}
            </p>
            {propertyOpen && (
              <div>
                <p className="  border rounded-full px-2 py-1 mb-2 text-gray-600">+ Residential</p>
                <p className="  border rounded-full px-2 py-1 mb-2 text-gray-600">+ Commercial</p>
                <p className="  border rounded-full px-2 py-1 mb-2 text-gray-600">+ Industrial</p>
                <p className="  border rounded-full px-2 py-1 mb-2 text-gray-600">+ Agricultural</p>
                <p className="  border rounded-full px-2 py-1 mb-2 text-gray-600">+ PG / Co-living</p>
              </div>
            )}
          </div>

          <br />

          {/* Number of Bedrooms Section */}
          <div>
            <p className="cursor-pointer font-semibold text-lg mb-3 flex w-full justify-between" onClick={() => setBedroomsOpen(!bedroomsOpen)}>
              <p>No. of Bedrooms</p>
              {bedroomsOpen ? <p><FaChevronUp size={15} className="text-gray-600 " /></p> : <p><FaChevronDown size={15} className="text-gray-600 " /></p>}
            </p>
            {bedroomsOpen && (
              <div>
                <div className="flex">
                  <p className="border rounded-full px-2 py-1 mb-2 text-gray-600 mr-3">+ 1 RK/1 BHK</p>
                  <p className="border rounded-full px-2 py-1 mb-2 text-gray-600">+ 2 BHK</p>
                </div>
                <div className="flex">
                  <p className="border rounded-full px-2 py-1 mb-2 text-gray-600 mr-3">+ 3 BHK</p>
                  <p className="border rounded-full px-2 py-1 mb-2 text-gray-600 mr-3">+ 4 BHK</p>
                  <p className="border rounded-full px-2 py-1 mb-2 text-gray-600 mr-3">+ 5 BHK</p>
                </div>
              </div>
            )}
          </div>

<br />
          {/* Construction Status Section */}
          <div>
            <p className="cursor-pointer font-semibold text-lg mb-3 flex w-full justify-between" onClick={() => setConstructionOpen(!constructionOpen)}>
              <p>Construction Status</p>
              {constructionOpen ? <p><FaChevronUp size={15} className="text-gray-600 " /></p> : <p><FaChevronDown size={15} className="text-gray-600 " /></p>}
            </p>
            {constructionOpen && (
              <div>
                <p className="border rounded-full px-2 py-1 mb-2 text-gray-600">+ New Launch</p>
                <p className="border rounded-full px-2 py-1 mb-2 text-gray-600">+ Under Construction</p>
                <p className="border rounded-full px-2 py-1 mb-2 text-gray-600">+ Ready to Move</p>
              </div>
            )}
          </div>
<br />
          {/* Area Section */}
          <div>
            <p className="cursor-pointer font-semibold text-lg mb-3 flex w-full justify-between" onClick={() => setPropertyOpen(!propertyOpen)}>
              <p>Area</p>
              {propertyOpen ? <p><FaChevronUp size={15} className="text-gray-600 " /></p> : <p><FaChevronDown size={15} className="text-gray-600 " /></p>}
            </p>
            {propertyOpen && (
              <div>
                <p className="text-sm text-gray-500 my-3">sqft</p>
                <div className="flex flex-col">
                  <div className="flex justify-between mt-2">
                    <input
                      type="range"
                      min="0"
                      max="20000"
                      value={maxArea}
                      onChange={(e) => setMaxArea(Number(e.target.value))}
                      className="w-full h-1 cursor-pointer"
                    />
                  </div>
                  <label className="mt-2 text-sm text-gray-600">Max Area: {maxArea} sq ft</label>
                </div>
              </div>
            )}
          </div>
<br />
          {/* Amenities Section */}
          <div>
          <p className="cursor-pointer font-semibold text-lg mb-3 flex w-full justify-between" onClick={() => setAmenitiesOpen(!amenitiesOpen)}>
              <p>Amenities</p>
              {amenitiesOpen ? <p><FaChevronUp size={15} className="text-gray-600 "/></p> : <p><FaChevronDown  size={15} className="text-gray-600 "/></p>}
            </p>
            {amenitiesOpen && (
              <div>
                <div className="flex mb-2">
                  <p className="border rounded-full px-2 py-1 mr-2 text-gray-600">+ Park</p>
                  <p className="border rounded-full px-2 py-1 mr-2 text-gray-600">+ Lift</p>
                  <p className="border rounded-full px-2 py-1 mr-2 text-gray-600">+ Parking</p>
                </div>
                <div className="flex">
                  <p className="border rounded-full px-2 py-1 mr-2 text-gray-600">+ Gymnasium</p>
                  <p className="border rounded-full px-2 py-1 mr-2 text-gray-600">+ Swimming Pool</p>
                </div>
              </div>
            )}
          </div>
<br />
          {/* Property Selling Type Section */}
          <div>
          <p className="cursor-pointer font-semibold text-lg mb-3 flex w-full justify-between" onClick={() => setSellingTypeOpen(!sellingTypeOpen)}>
              <p>Property Selling Type</p>
              {sellingTypeOpen ? <p><FaChevronUp size={15} className="text-gray-600 "/></p> : <p><FaChevronDown  size={15} className="text-gray-600 "/></p>}
            </p>
            {sellingTypeOpen && (
              <div>
                <p className="border rounded-full px-2 py-1 mb-2 text-gray-600">+ Buy</p>
                <p className="border rounded-full px-2 py-1 mb-2 text-gray-600">+ Lease</p>
                <p className="border rounded-full px-2 py-1 mb-2 text-gray-600">+ Rental</p>
              </div>
            )}
          </div>
        </div>


        <div className="flex flex-col">
          <p className="text-lg text-gray-700 font-semibold">
            800 Results | Property in Jabalpur</p>
          <div className="gap-5 flex flex-col">
            {data.map((item) => (
              <SearchCard item={item} />
            ))}
          </div>
        </div>

      </section>
    </>
  )
}

export default SearchPage