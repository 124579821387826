import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import PropertyPage from './pages/PropertyPage';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/search/:keyword" element={<SearchPage/>}/>
          <Route path="/property" element={<PropertyPage/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
