import React, { useState, useEffect } from 'react';
import { IoIosMenu } from "react-icons/io";
import { GiHouseKeys } from "react-icons/gi";
import { FaChevronDown } from 'react-icons/fa';
import { FaMicrophone } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { FaCaretRight } from "react-icons/fa";
import { MdMyLocation } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import {Link} from 'react-router-dom'
import '../Animations.css';

const imageUrls = [
  "https://picsum.photos/2000/600?random=1",
  "https://picsum.photos/2000/600?random=2",
  "https://picsum.photos/2000/600?random=3",
  "https://picsum.photos/2000/600?random=4"
];

const TopBar2 = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showPropertyPopup, setShowPropertyPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedPropertyOption, setSelectedPropertyOption] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  const options = ["Sell", "Rent/Lease", "PG"];
  const propertyType = ["Flat/Apartment", "Independent House / Villa", "Independent House / Builder Floor", "Plot / Land", "Other"]

  return (
    <div className="flex flex-col bg-blue-800 shadow h-[75px] relative">
      <div className='w-full z-[10] h-20 bg-transparent flex items-center justify-between px-10'>
        <Link to="/"><div className="font-bold text-white text-xl flex items-center cursor-pointer">
          <GiHouseKeys size={40} />
          rentalWeb
        </div></Link>
        <div className="flex">
          <input type="text" placeholder="Enter Locality / Project / Society / Landmark" className="text-sm py-[15px] rounded px-5 w-[500px]" />
          <button className="bg-white  rounded px-5 ml-1"><FiSearch size={20} /></button>
        </div>
        <div className="flex items-center">
          <button
            className="shadow py-1 px-3 rounded-md text-sm font-semibold mr-5 bg-white flex items-center"
            onClick={() => setShowPropertyPopup(true)}
          >
            Post Property <div className="ml-2 uppercase bg-green-600 text-white text-[11px] flex items-center font-bold px-2 rounded">Free</div>
          </button>
          <div className="flex items-center border rounded-full px-3 py-2 cursor-pointer" onClick={() => setShowPopup(true)}>
            <IoIosMenu size={26} className="text-gray-100 mr-2" />
            <div className="w-8 h-8 rounded-full bg-gray-500 overflow-hidden">
                <img src="https://picsum.photos/300/300" alt="" />
            </div>
          </div>
        </div>
      </div>


      {/* Sidebar Popup */}
      <div className={`absolute top-0 bg-white h-screen w-[350px] z-[20] ${showPopup ? 'right-0' : 'right-[-380px]'} transition-all duration-300`}>
        <div className="flex h-16 items-center justify-between px-5">
          <div className="w-8 h-8 rounded-full bg-gray-200"></div>
          <button className="font-semibold text-lg cursor-pointer text-gray-500" onClick={() => setShowPopup(false)}><FaX /></button>
        </div>
        <div className="flex flex-col px-10 cursor-pointer hover:bg-gray-200">
          <div className="border-b py-5 cursor-pointer font-semibold text-sm text-gray-800">Post Property</div>
        </div>
        <div className="flex flex-col px-10 cursor-pointer hover:bg-gray-200">
          <div className="border-b py-5 cursor-pointer text-sm  text-gray-700">Explore Our Services</div>
        </div>
        <div className="flex flex-col px-10">
          <div className="font-semibold mt-5 cursor-pointer text-sm  hover:text-gray-900 text-gray-500 flex items-center"><FaCaretRight className="mr-1" />Request a video tour</div>
        </div>
        <div className="flex flex-col px-10 ">
          <div className="font-semibold mt-2 cursor-pointer text-sm  hover:text-gray-900 text-gray-500 flex items-center "><FaCaretRight className="mr-1" />Request For site Visit</div>
        </div>
        <div className="absolute w-full flex flex-col justify-center items-center bottom-8 px-10">
          <div className="h-10 w-full bg-gray-200 text-gray-900 flex items-center pr-2 ">
            <input type="text" placeholder='Search By Property Code' className="w-full h-full text-sm rounded pl-3 text-gray-900 bg-gray-200" />
            <FiSearch className="text-gray-700" size={22} />
          </div>
          <div className="text-xs text-gray-700 text-left flex w-full  mt-3">
            Toll Free Number: +91 1234567890
          </div>
        </div>
      </div>

      {/* Post Property Popup */}
      <div className={`fixed top-0 left-0 bg-[#1a1a1db1] h-screen w-full z-[3000] ${showPropertyPopup ? 'block' : 'hidden'}`}>
        <div className="flex flex-col  p-5 bg-white rounded-md max-w-md mx-auto mt-10">
          <button className="self-end font-semibold text-lg cursor-pointer text-gray-500 mb-5" onClick={() => setShowPropertyPopup(false)}><FaX /></button>
          <p className="mb-5 text-xl font-semibold text-gray-800">Start posting your property, it's free</p>
          <p className="mb-3 text-xs font-semibold text-gray-600">Add Basic Details</p>
          <p className="mb-5 font-semibold text-gray-800">You're looking to ...</p>
          <div className="flex space-x-3">
            {options.map((option, index) => (
              <button
                key={index}
                className={`py-1 px-3 rounded-full text-gray-600  ${selectedOption === option ? 'border-2 border-blue-600' : 'border'}`}
                onClick={() => setSelectedOption(option)}
              >
                {option}
              </button>
            ))}
          </div>
          <p className="mt-5 mb-2 font-semibold text-gray-800">And it's a ...</p>
          <div className="flex space-x-3">
            <input type="radio"  name="Residential" id="" />
            <p className="text-gray-600">Residential</p>
            <input type="radio" name="Residential" id="" />
            <p className="text-gray-600">Commercial</p>
          </div>
          <div className="mt-4">
            {propertyType.map((option, index) => (
              <button
                key={index}
                className={`py-2 text-sm text-gray-600 mr-4 mb-4 px-3 rounded-full ${selectedPropertyOption === option ? 'border-2 border-blue-600' : 'border'}`}
                onClick={() => setSelectedPropertyOption(option)}
              >
                {option}
              </button>
            ))}
          </div>
          <p className="mb-5 font-semibold text-gray-800">Your contact details for the buyer to reach you</p>
          <input type="text" className="border px-3 py-4 text-sm rounded" placeholder="Phone Number" />
          <button className="bg-blue-600 text-white font-bold py-4 rounded mt-5">Start Now</button>

        </div>
      </div>

    </div>
  );
}

export default TopBar2;
