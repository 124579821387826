import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import TopBar2 from '../components/TopBar2'
import Footer from '../components/Footer'

const PropertyPage = () => {
  const location = useLocation();
  console.log(location);

  return (
    <>
      <TopBar2 />
      <div className="flex justify-between items-center pl-32 pr-20 py-10">
        <div className="flex gap-4">
          <div className="flex flex-col gap-2 border-r border-[#1a1a1d48] pr-5">
            <div className="flex font-bold text-gray-500 text-4xl items-end">₹75 Lac <span className="font-normal text-gray-400 text-base ml-2">@4,411 per sq.ft.</span></div>
            <div className="flex text-blue-600">Estimated EMI ₹59,903</div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-gray-600 text-3xl">3BHK 2Baths</div>
            <div className="text-gray-400">Flat/Apartment for Sale</div>
            <div className="text-gray-400 text-sm">in ABC address, Indore, Madhya Pradesh, </div>
          </div>
        </div>

        <div>
          <button className="text-white bg-blue-500 px-4 py-2 font-semibold">Contact Owner</button>
        </div>
      </div>

      <hr />

      <div className="flex items-center justify-center py-10">
        <div className="rounded-xl overflow-hidden">
          <img src="https://picsum.photos/540/450" alt="" />
        </div>
        <div className="flex gap-20 ml-12">
          <div className="flex flex-col gap-8">
            <div>
              <p className="text-gray-500 font-semibold">Area</p>
              <p className="font-semibold">Carpet area: <span className="text-blue-600">1700 sq.ft.</span></p>
              <p className="text-sm text-gray-500">(157.94 sq.m.)</p>
            </div>

            <div>
              <p className="text-gray-500 font-semibold">Area</p>
              <p className="font-semibold">Carpet area: <span className="text-blue-600">1700 sq.ft.</span></p>
              <p className="text-sm text-gray-500">(157.94 sq.m.)</p>
            </div>

            <div>
              <p className="text-gray-500 font-semibold">Area</p>
              <p className="font-semibold">Carpet area: <span className="text-blue-600">1700 sq.ft.</span></p>
              <p className="text-sm text-gray-500">(157.94 sq.m.)</p>
            </div>

            <div>
              <p className="text-gray-500 font-semibold">Area</p>
              <p className="font-semibold">Carpet area: <span className="text-blue-600">1700 sq.ft.</span></p>
              <p className="text-sm text-gray-500">(157.94 sq.m.)</p>
            </div>

          </div>
          <div className="flex flex-col  gap-8">
            <div>
              <p className="text-gray-500 font-semibold">Area</p>
              <p className="font-semibold">Carpet area: <span className="text-blue-600">1700 sq.ft.</span></p>
              <p className="text-sm text-gray-500">(157.94 sq.m.)</p>
            </div>
            <div>
              <p className="text-gray-500 font-semibold">Area</p>
              <p className="font-semibold">Carpet area: <span className="text-blue-600">1700 sq.ft.</span></p>
              <p className="text-sm text-gray-500">(157.94 sq.m.)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex px-32 text-xl font-semibold flex-col gap-5">


        <p>Why you should consider this property?</p>
        <div className="h-28 bg-blue-100 rounded p-3  w-full flex">
          <div className="flex h-full flex-col justify-end pl-5 w-[220px]  font-semibold text-lg">
            Key Highlights <br /> <span className="font-normal text-base">of this property</span>
          </div>
          <div className="h-full w-full bg-white rounded flex justify-between">
            <div className="grid grid-rows-2 grid-cols-3 w-full font-normal text-base place-items-center">
              <p>East Facing</p>
              <p>East Facing</p>
              <p>East Facing</p>
              <p>East Facing</p>
              <p>East Facing</p>
              <p>East Facing</p>
            </div>
          </div>
        </div>


        <hr />

        <div className="w-full grid grid-cols-4 gap-2">
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
          <p className="text-gray-500 text-base font-normal">Transaction Type : <span className="font-semibold text-gray-700">Resale</span></p>
        </div>

        <hr />

        <div className="gap-2 flex flex-col">
          <p className="text-gray-700">About Property</p>
          <p className="text-base text-gray-700 ">Address : <span className="font-normal">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</span></p>
          <p className="text-base font-normal text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, accusantium dolorum voluptatem temporibus nulla, quisquam, ut cumque error pariatur dolores vitae. Accusamus ipsa sit amet deserunt ducimus adipisci odio fugiat, cupiditate quaerat! Sapiente velit iusto voluptatibus non aliquid maiores vel quod praesentium, ipsa natus quia facere laudantium! Nesciunt, est illum.</p>
        </div>

        <hr />

        <div className="gap-2 flex flex-col">
          <p className="text-gray-700">Furnished</p>
          <p className="text-base text-gray-700 font-normal">Furnishing Details</p>
          <div className="w-full grid grid-cols-6  text-base font-normal gap-2 text-start text-gray-500">
            <p>1 Water Purifier</p>
            <p>5 Fan</p>
            <p>1 Fridge</p>
            <p>1 Exhaust Fan</p>
            <p>1 Dining Table</p>
            <p>1 Stove</p>
            <p>14 Light</p>
            <p>1 TV</p>
            <p>1 Modular Kitchen</p>
            <p>1 Chimney</p>
            <p>3 Bed</p>
            <p>4 Wardrobe</p>
            <p>1 Sofa</p>
          </div>
        </div>

        <hr />
      </div>

      <div className="w-full bg-gray-100 flex justify-center gap-20 py-10">
        <div className="rounded-xl overflow-hidden">
          <img src="https://picsum.photos/500/350" alt="" />
        </div>

        <div >
          <p className="text-gray-400 text-lg mb-4 font-semibold">Send enquiry to Owner</p>
          <div className="flex text-gray-500">
            You are <input type="radio" name="" id="" className="ml-3 mr-1" /> Individual <input type="radio" name="" id="" className="mr-1 ml-3" /> Dealer
          </div>
          <div className="flex text-gray-500">
            You reason to buy is <input type="radio" className="ml-3 mr-1" name="" id="" /> Investment <input type="radio" className="mr-1 ml-3" name="" id="" /> Self use
          </div>
          <div className="flex flex-col gap-2 pt-5">
            <input className="py-1 pl-2" type="text" placeholder="Name" />
            <input className="py-1 pl-2 mb-2" type="email" placeholder="Email" />
            <input className="py-1 pl-2" type="number" placeholder="Phone Number" name="" id="" />
            <div className="flex text-gray-500 my-1">
              <input type="checkbox" name="" id="" className="mr-1" /> I agree to the <Link className="text-blue-500">&nbsp;Terms & Conditions</Link>&nbsp;and&nbsp;<Link className="text-blue-500">Privacy Policy</Link>
            </div>
            <button className="bg-blue-500 text-white font-semibold w-[150px] py-2">Send Enquiry</button>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default PropertyPage