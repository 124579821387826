import React from 'react'
import ExclusiveCarousel from './ExclusiveCarousel'

const ExclusiveCarouselCard = ({name,images,profile,description,pricerange}) => {
  return (
    <div className="flex flex-col items-center w-[420px] rounded-lg h-[380px] bg-transparent  relative">
        <div className="w-full h-[230px] rounded-t-lg overflow-hidden rounded-b-md">
            <img src={images[0]} alt="" />
        </div>
        <div className="absolute bg-white w-[350px] h-[180px] rounded-lg shadow-lg bottom-10 flex flex-col items-start text-start px-5">
            <div className="absolute w-20 h-20 top-[-40px] left-[30px] rounded-full overflow-hidden border-2">
                <img src="https://picsum.photos/300/300" alt="" />
            </div>
            <p className="font-bold mt-14">{name}</p>
            <p className="text-gray-500 text-sm">{description}</p>
            <p className="mt-4 font-semibold ">{pricerange}</p>
        </div>
    </div>
  )
}

export default ExclusiveCarouselCard