import React, { useState, useEffect } from 'react';
import { IoIosMenu } from "react-icons/io";
import { GiHouseKeys } from "react-icons/gi";
import { FaChevronDown } from 'react-icons/fa';
import { FaMicrophone } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { FaCaretRight } from "react-icons/fa";
import { MdMyLocation } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import '../Animations.css';

const imageUrls = [
  "https://picsum.photos/2000/600?random=1",
  "https://picsum.photos/2000/600?random=2",
  "https://picsum.photos/2000/600?random=3",
  "https://picsum.photos/2000/600?random=4"
];

const TopBar = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showPropertyPopup, setShowPropertyPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedPropertyOption, setSelectedPropertyOption] = useState(null);

  const [activeCategory, setActiveCategory] = useState("Commercial");
  const [actionType, setActionType] = useState("Buy");

  const categories = ["Commercial", "Industrial", "Agricultural", "PG / Co-living"];

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    // Perform action based on the category and actionType
    console.log(`Selected category: ${category}, Action: ${actionType}`);
  };

  const handleActionClick = (action) => {
    setActionType(action);
    // Perform action based on the activeCategory and actionType
    console.log(`Selected category: ${activeCategory}, Action: ${action}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  const options = ["Sell", "Rent/Lease", "PG"];
  const propertyType = ["Flat/Apartment", "Independent House / Villa", "Independent House / Builder Floor", "Plot / Land", "Other"]

  return (
    <div className="flex flex-col h-[450px] relative">
      <div className="absolute top-0 left-0 z-[1] w-full h-full overflow-hidden">
        <img
          src={imageUrls[currentImageIndex]}
          className="h-full w-full animate-zoom"
          alt="Background"
        />
      </div>
      <div className='w-full z-[10] h-20 bg-transparent flex items-center justify-between px-10'>
        <div className="font-bold text-red-500 text-xl flex items-center">
          <GiHouseKeys size={40} />
          rentalWeb
        </div>
        <div>
          {/* Add additional elements here if needed */}
        </div>
        <div className="flex items-center">
          <button
            className="shadow py-1 px-3 rounded-md text-sm font-semibold mr-5 bg-white flex items-center"
            onClick={() => setShowPropertyPopup(true)}
          >
            Post Property <div className="ml-2 uppercase bg-green-600 text-white text-[11px] flex items-center font-bold px-2 rounded">Free</div>
          </button>
          <div className="flex items-center border rounded-full px-3 py-2 cursor-pointer" onClick={() => setShowPopup(true)}>
            <IoIosMenu size={26} className="text-gray-100 mr-2" />
            <div className="w-8 h-8 rounded-full bg-gray-500"></div>
          </div>
        </div>
      </div>

      <div className="z-[20] absolute flex items-center justify-center left-0 bottom-[-60px] w-full">
      <div className="bg-white rounded-2xl shadow-lg h-32 justify-center flex flex-col">
        <div className="flex h-[50%] items-center px-7">
          <ul className="flex text-[17px] font-semibold h-full items-center">
            {categories.map((category) => (
              <li
                key={category}
                onClick={() => handleCategoryClick(category)}
                className={`mr-10 h-full flex items-center cursor-pointer ${
                  activeCategory === category
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-500"
                }`}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-between items-end h-[50%]">
          <div className="border-t border-r h-full items-center flex px-5 text-gray-500 font-semibold w-[300px]">
            <button
              className={`cursor-pointer rounded w-20 h-9 ${
                actionType === "Buy" ? "bg-blue-600 text-white" : "border-2 border-blue-600 text-blue-600"
              }`}
              onClick={() => handleActionClick("Buy")}
            >
              Buy
            </button>
            <button
              className={`cursor-pointer border-2 border-blue-600 rounded w-20 h-9 ml-4 ${
                actionType === "Rent" ? "bg-blue-600 text-white" : "text-blue-600"
              }`}
              onClick={() => handleActionClick("Rent")}
            >
              Rent
            </button>
          </div>
          <div className="border-t h-full w-full text-gray-900">
            {activeCategory == "Commercial" && <input
              type="text"
              placeholder='Search "Office space for rent in Indore"'
              className="w-full h-full pl-10 text-xs text-gray-900"
            />}
            {
              activeCategory == "Industrial" && <input
                type="text"
                placeholder='Search "Warehouse for lease in Indore"'
                className="w-full h-full pl-10 text-xs text-gray-900"
              />
            }
            {
              activeCategory == "Agricultural" && <input
                type="text"
                placeholder='Search "Farmland for rent near Indore"'
                className="w-full h-full pl-10 text-xs text-gray-900"
              />
            }
            {
              activeCategory == "PG / Co-living" && <input
                type="text"
                placeholder='Search "PG accommodation for rent in Indore"'
                className="w-full h-full pl-10 text-xs text-gray-900"
              />
            }
          </div>
          <div className="flex h-full items-center ml-2 border-t">
            <div className="h-10 flex items-center justify-center w-10 rounded-full bg-blue-50">
              <MdMyLocation size={22} className="text-blue-600" />
            </div>
            <div className="h-10 flex items-center ml-2 justify-center w-10 rounded-full bg-blue-50">
              <FaMicrophone size={21} className="text-blue-600" />
            </div>
            <button className="text-white bg-blue-600 rounded px-3 mr-2 ml-2 py-1">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

      {/* Sidebar Popup */}
      <div className={`hidden sm:block absolute top-0 bg-white h-screen w-[350px] z-[20] ${showPopup ? 'right-0' : 'right-[-380px]'} transition-all duration-300`}>
        <div className="flex h-16 items-center justify-between px-5">
          <div className="w-8 h-8 rounded-full bg-gray-200"></div>
          <button className="font-semibold text-lg cursor-pointer text-gray-500" onClick={() => setShowPopup(false)}><FaX /></button>
        </div>
        <div className="flex flex-col px-10 cursor-pointer hover:bg-gray-200">
          <div className="border-b py-5 cursor-pointer font-semibold text-sm text-gray-800">Post Property</div>
        </div>
        <div className="flex flex-col px-10 cursor-pointer hover:bg-gray-200">
          <div className="border-b py-5 cursor-pointer text-sm  text-gray-700">Explore Our Services</div>
        </div>
        <div className="flex flex-col px-10">
          <div className="font-semibold mt-5 cursor-pointer text-sm  hover:text-gray-900 text-gray-500 flex items-center"><FaCaretRight className="mr-1" />Request a video tour</div>
        </div>
        <div className="flex flex-col px-10 ">
          <div className="font-semibold mt-2 cursor-pointer text-sm  hover:text-gray-900 text-gray-500 flex items-center "><FaCaretRight className="mr-1" />Request For site Visit</div>
        </div>
        <div className="absolute w-full flex flex-col justify-center items-center bottom-8 px-10">
          <div className="h-10 w-full bg-gray-200 text-gray-900 flex items-center pr-2 ">
            <input type="text" placeholder='Search By Property Code' className="w-full h-full text-sm rounded pl-3 text-gray-900 bg-gray-200" />
            <FiSearch className="text-gray-700" size={22} />
          </div>
          <div className="text-xs text-gray-700 text-left flex w-full  mt-3">
            Toll Free Number: +91 1234567890
          </div>
        </div>
      </div>

      {/* Post Property Popup */}
      <div className={`fixed top-0 left-0 bg-[#1a1a1db1] h-screen w-full z-[3000] ${showPropertyPopup ? 'block' : 'hidden'}`}>
        <div className="flex flex-col  p-5 bg-white rounded-md max-w-md mx-auto mt-10">
          <button className="self-end font-semibold text-lg cursor-pointer text-gray-500 mb-5" onClick={() => setShowPropertyPopup(false)}><FaX /></button>
          <p className="mb-5 text-xl font-semibold text-gray-800">Start posting your property, it's free</p>
          <p className="mb-3 text-xs font-semibold text-gray-600">Add Basic Details</p>
          <p className="mb-5 font-semibold text-gray-800">You're looking to ...</p>
          <div className="flex space-x-3">
            {options.map((option, index) => (
              <button
                key={index}
                className={`py-1 px-3 rounded-full text-gray-600  ${selectedOption === option ? 'border-2 border-blue-600' : 'border'}`}
                onClick={() => setSelectedOption(option)}
              >
                {option}
              </button>
            ))}
          </div>
          <p className="mt-5 mb-2 font-semibold text-gray-800">And it's a ...</p>
          <div className="flex space-x-3">
            <input type="radio"  name="Residential" id="" />
            <p className="text-gray-600">Residential</p>
            <input type="radio" name="Residential" id="" />
            <p className="text-gray-600">Commercial</p>
          </div>
          <div className="mt-4">
            {propertyType.map((option, index) => (
              <button
                key={index}
                className={`py-2 text-sm text-gray-600 mr-4 mb-4 px-3 rounded-full ${selectedPropertyOption === option ? 'border-2 border-blue-600' : 'border'}`}
                onClick={() => setSelectedPropertyOption(option)}
              >
                {option}
              </button>
            ))}
          </div>
          <p className="mb-5 font-semibold text-gray-800">Your contact details for the buyer to reach you</p>
          <input type="text" className="border px-3 py-4 text-sm rounded" placeholder="Phone Number" />
          <button className="bg-blue-600 text-white font-bold py-4 rounded mt-5">Start Now</button>

        </div>
      </div>

    </div>
  );
}

export default TopBar;
