import React from 'react'
import TestimonialCarousel from './TestimonialCarousel'

const TestimonialCarouselCard = ({ name, profile, address, text }) => {
  return (
    <div className="flex flex-col bg-white  w-[420px] rounded-lg h-[180px]  bg-transparent  relative shadow-xl border">
      <div className="flex p-4">
        <div className="w-12 h-12 rounded-full overflow-hidden"><img src="https://picsum.photos/300/301" alt="" /></div>
        <div className="flex flex-col">
          <p className="font-semibold ml-5">{name}</p>
          <p className="text-xs text-gray-700">{address}</p>
        </div>
      </div>
      <p className="text-[17px] text-start px-5">{text}</p>
    </div>
  )
}

export default TestimonialCarouselCard