import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Carousel.css';
import { Pagination, Navigation } from 'swiper/modules';
import TestimonialCarouselCard from './TestimonialCarouselCard';

const TestimonialCarousel = () => {
    const data = [
        {
            name:"Rajyash Regius",
            address:"Owner, Hyderabad",
            profile:"https://picsum.photos/200/200?random=1",
            text:"You get an exclusive RM from rental web team who tracks your property closely"
        },
        {
            name:"Rajyash Regius",
            address:"Owner, Hyderabad",
            profile:"https://picsum.photos/200/200?random=1",
            text:"You get an exclusive RM from rental web team who tracks your property closely"
        },
        {
            name:"Rajyash Regius",
            address:"Owner, Hyderabad",
            profile:"https://picsum.photos/200/200?random=1",
            text:"You get an exclusive RM from rental web team who tracks your property closely"
        },
        {
            name:"Rajyash Regius",
            address:"Owner, Hyderabad",
            profile:"https://picsum.photos/200/200?random=1",
            text:"You get an exclusive RM from rental web team who tracks your property closely"
        }
        
    ]
    return (
        <>
            <Swiper
                // slidesPerView={3}
                spaceBetween={0}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 2.5,
                        spaceBetween: 0,
                    },
                }}
                modules={[ Navigation]}
                navigation={true}
                loop={false}
                infinite={true}
                className="mySwiper px-20 bg-transparent z-[1000]"
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index} className="px-5 bg-transparent z-[1000]">
                        <TestimonialCarouselCard
                            name={item.name}
                            profile={item.profile}
                            text={item.text} 
                            address={item.address}
                            className="cursor-pointer"
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}

export default TestimonialCarousel;
