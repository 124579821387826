import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Carousel.css';
import { Pagination, Navigation } from 'swiper/modules';
import ExclusiveCarouselCard from './ExclusiveCarouselCard';

const ExclusiveCarousel = () => {
    const data = [
        {
            name:"Rajyash Regius",
            description:"4.5 BHK Apartment, Bhopal, Ahmedabad West",
            images:["https://picsum.photos/400/301?random=1","https://picsum.photos/400/301?random=2"],
            profile:"https://picsum.photos/200/200?random=1",
            pricerange:"₹1.63 - 4.02 Cr"
        },
        {
            name:"Rajyash Regius",
            description:"4.5 BHK Apartment, Bhopal, Ahmedabad West",
            images:["https://picsum.photos/400/301?random=1","https://picsum.photos/400/301?random=2"],
            profile:"https://picsum.photos/200/200?random=1",
            pricerange:"₹1.63 - 4.02 Cr"
        },
        {
            name:"Rajyash Regius",
            description:"4.5 BHK Apartment, Bhopal, Ahmedabad West",
            images:["https://picsum.photos/400/301?random=1","https://picsum.photos/400/301?random=2"],
            profile:"https://picsum.photos/200/200?random=1",
            pricerange:"₹1.63 - 4.02 Cr"
        },
        {
            name:"Rajyash Regius",
            description:"4.5 BHK Apartment, Bhopal, Ahmedabad West",
            images:["https://picsum.photos/400/301?random=1","https://picsum.photos/400/301?random=2"],
            profile:"https://picsum.photos/200/200?random=1",
            pricerange:"₹1.63 - 4.02 Cr"
        },
        {
            name:"Rajyash Regius",
            description:"4.5 BHK Apartment, Bhopal, Ahmedabad West",
            images:["https://picsum.photos/400/301?random=1","https://picsum.photos/400/301?random=2"],
            profile:"https://picsum.photos/200/200?random=1",
            pricerange:"₹1.63 - 4.02 Cr"
        },
        {
            name:"Rajyash Regius",
            description:"4.5 BHK Apartment, Bhopal, Ahmedabad West",
            images:["https://picsum.photos/400/301?random=1","https://picsum.photos/400/301?random=2"],
            profile:"https://picsum.photos/200/200?random=1",
            pricerange:"₹1.63 - 4.02 Cr"
        }
    ]
    return (
        <>
            <Swiper
                // slidesPerView={3}
                spaceBetween={0}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 2.5,
                        spaceBetween: 0,
                    },
                }}
                modules={[ Navigation]}
                navigation={true}
                loop={false}
                infinite={true}
                className="mySwiper px-20 bg-transparent"
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index} className="px-5 bg-transparent">
                        <ExclusiveCarouselCard 
                            name={item.name}
                            description={item.description} 
                            images={item.images} 
                            pricerange={item.pricerange}
                            profile={item.profile}
                            className="cursor-pointer"
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}

export default ExclusiveCarousel;
