import React from 'react'

const CarouselCard = ({name,image}) => {
  return (
    <div className="flex flex-col justify-center items-center w-[200px] h-[250px] ">
        <div className="h-[200px]  rounded-lg overflow-hidden w-full"><img className="h-full w-full object-cover" src={image} alt="" /></div>
        <p className="text-start w-full text-[15px] text-gray-700 font-semibold mt-2">{name}</p>
    </div>
  )
}

export default CarouselCard