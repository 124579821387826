import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Carousel.css';
import { Pagination, Navigation } from 'swiper/modules';
import CarouselCard from './CarouselCard';

const Carousel = () => {
    const data = [
        {
            name:"Rental One",
            image:"https://picsum.photos/200/100?random=1"
        },
        {
            name:"Rental Two",
            image:"https://picsum.photos/200/100?random=2"
        },
        {
            name:"Rental Three",
            image:"https://picsum.photos/200/100?random=3"
        },
        {
            name:"Rental Four",
            image:"https://picsum.photos/200/100?random=4"
        },
        {
            name:"Rental Five",
            image:"https://picsum.photos/200/100?random=5"
        },
        {
            name:"Rental Six",
            image:"https://picsum.photos/200/100?random=6"
        },
        {
            name:"Rental Seven",
            image:"https://picsum.photos/200/100?random=7"
        },
        {
            name:"Rental Eight",
            image:"https://picsum.photos/200/100?random=8"
        },
    ]
    return (
        <>
            <Swiper
                // slidesPerView={3}
                spaceBetween={0}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 0,
                    },
                }}
                modules={[ Navigation]}
                navigation={true}
                loop={false}
                infinite={true}
                className="mySwiper px-20"
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index} className="px-5 cursor-pointer">
                        <CarouselCard 
                            name={item.name} 
                            image={item.image} 
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}

export default Carousel;
