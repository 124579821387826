import React from 'react';
import {useNavigate} from 'react-router-dom'

const SearchCard = ({item}) => {
    const navigate = useNavigate();
  return (
    <div className="flex p-3 bg-white rounded-lg h-[250px] w-[800px] " >
        <div className="flex rounded-lg overflow-hidden min-w-[350px]">
            <img src="https://picsum.photos/300/300" className="w-full" alt="" />
        </div>
        <div className="flex flex-col ml-4 w-full">
            <p className="capitalize font-semibold cursor-pointer" onClick={()=>{navigate("/property")}}>{item.name}</p>
            <p><span className="font-semibold">{item.propertySpecs}&nbsp;</span>in {item.location}</p>

            <div className="mt-2 flex items-center">
                <div className="flex flex-col border-r justify-center pr-5">
                    <p className="font-bold text-xl">₹{item.price}</p>
                    <p className=" text-sm">₹ {item.pricePerSqft} /sqft</p>
                </div>
                <div className="flex flex-col border-r justify-center pr-5">
                    <p className="font-bold text-lg">{item.area} sqft <span className="font-normal text-sm">(245m)</span></p>
                    <p className=" text text-gray-600">{item.areaType}</p>
                </div>
                <div className="flex flex-col border-r justify-center pr-5">
                    <p className="font-bold text-lg">{item.propertySpecs} <span className="font-normal text-sm">(2 Baths)</span></p>
                    <p className=" text text-gray-600">{item.propertyStatus}</p>
                </div>
            </div>
            <p className="mt-2 text-gray-600 font-light">{item.description}...</p>
            <div className="flex justify-between items-center mt-6">
                <p>{item.owner}</p>
                <div>
                    <button className="border-2 border-blue-600 rounded w-32 text-blue-600 h-9">View Number</button>
                    <button className=" bg-blue-600 rounded w-32 text-white h-9 ml-2">Contact</button>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default SearchCard